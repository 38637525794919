<template>
    <div class="Background">
        <div class="well top"
             style="
            background: #00589d;
            padding-bottom: 0.4% !important;
            padding-top: 0.5% !important;
          ">
            <a>
                <img class="inline vertically-middle logo-img"
                     src="./../assets/logo.png"
                     style="max-width:  calc(4vw + 0.1vh + 2vmin);" />
            </a>
            <div class="inline-table vertically-super LeftPadding10">
                <h4 class="margin-zero title-heading" style="font-size:calc(1vw + 0.1vh + 2vmin);">IP Phone Camera</h4>
                <h6 class="title-sub-heading" style="font-size:calc(1vw + 0.1vh + 1vmin);">{{ ippc_desc }}</h6>
            </div>

        </div>

        <div>

            <div class="text-center">
                <h2 style="font-size: calc(1.3vw + 0.1vh + 2vmin);font-weight: 500; margin-top:1.5%"> {{ record1 }}</h2>
            </div>
            <div class="container-fluid" style="font-size: large">
                <div class="row">
                    <div class="col-lg-7" style="width: 50%; height: 100%">
                        <div class="embed-responsive embed-responsive-16by9"
                             style="margin-top: 15px;height: 100%">
                            <video class="embed-responsive-item" style="width:100%;border:1px solid black" controls autoplay>
                                <source src="https://deskshare.com/Tutorials/IPPC/IPPC-2.mp4" type="video/mp4">
                            </video>
                        </div>
                    </div>
                    <div id="contentDiv"
                         class="col-lg-5"
                         style="height: 100%; margin-top: 14px;width:50%">
                        <ul style="color: black;list-style-type: disc;">
                            <span style="
                  text-decoration: underline;
                  color: black;
                  margin-bottom: 2%;
                "><h2 style="font-size:calc(1.3vw + 0.1vh + 2vmin)">{{ use_smp }}</h2></span>
                            <li style="margin-top: 2vw; ; font-size:larger">{{ use_smp_1 }}</li>
                            <li style="font-size:larger">{{ smp_desc_1 }}</li>
                            <li style="font-size:larger">{{ smp_desc_2 }}</li>
                            <li style="font-size:larger">{{ smp_desc_3 }}</li>
                        </ul>
                        <a :href="smpVideoLink" target="_blank">
                            <input class="
                  btn btn-default btn-lg btn-block
                  responsive-width
                  font-sizechanges
                "
                                   type="button"
                                   :value="learn_more"
                                   style="
                  background-color: #fff;
                  border: none;
                  color: black;
                  text-color: #000;
                  padding: 10px;
                  text-align: center;
                  text-decoration: none;
                  display: inline-block;
                  font-size: calc(1vw + 0.1vh + 1vmin);;
                  margin: 4px 2px;
                  cursor: pointer;
                  margin-top: 0px;
                  margin-bottom: 20px;
                " />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script src="./../js/recordVideo.js"></script>



