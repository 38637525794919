import '@/assets/custom.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import data from './../translation/global.json'

export default {
    name: 'VideoRecord',
    data: function () {
        return {
            ippc_desc: 'Turn your mobile phone into an IP Camera',
            record1: 'Record video from IP Phone Camera using Security Monitor Pro on your PC',
            use_smp: 'Use Security Monitor Pro to:',
            use_smp_1: 'Monitor and record from multiple IP Cameras.',
            smp_desc_1: 'Set motion-triggered actions individually for each IP camera like record, take photo, email or FTP upload.',
            smp_desc_2: 'Schedule IP cameras to monitor for motion daily, weekly or any custom time.',
            smp_desc_3: 'Built-in Media Analyzer for quick review of all captured events.',
            learn_more: 'Learn more about Security Monitor Pro',
            smpVideoLink: 'https://www.deskshare.com/video-surveillance-software.aspx'
        };
    },

    created: function () {
        var old = localStorage.getItem("language_code")

        this.ippc_desc = data.TurnyourmobilephoneintoanIPCamera[old]
        this.record1 = data.RecordvideofromIPPhoneCamerausingSecurityMonitorProonyourPC[old]
        this.use_smp = data.UseSecurityMonitorProto[old]
        this.use_smp_1 = data.MonitorandrecordfrommultipleIPCameras[old]
        this.smp_desc_1 = data.SetmotiontriggeredactionsindividuallyforeachIPcameralikerecordtakephotoemailorFTPupload[old]
        this.smp_desc_2 = data.ScheduleIPcamerastomonitorformotiondailyweeklyoranycustomtime[old]
        this.smp_desc_3 = data.BuiltinMediaAnalyzerforquickreviewofallcapturedevents[old]
        this.learn_more = data.LearnMoreAboutSecurityMonitorPro[old]
        this.smpVideoLink = data.SmpVideoLink[old]

    }
}